<template>
	
	<div id="pdf-box">
		<DefaultLoading v-if="loading || loadingBar" :loading="loading || loadingBar" />
		<Alerts v-if="showAlert" :alertType="alertType" :alertMsg="alertMsg" />

		<v-container v-if="error && !otpDialog">
			<v-row class="fill-height mt-5" align-content="center" justify="center">
				<v-col cols="12">
					<v-alert text color="#FF000D" border="left" class="pa-2">
						<v-icon color="#FF000D">mdi-close-circle-outline</v-icon>
						<strong>{{ errorMsg }}</strong>
					</v-alert>
				</v-col>
			</v-row>
		</v-container>

		<v-container v-if="status === 'SIGNED'">
			<v-alert class="mt-5" border="left" text type="warning">
				{{ $t("document_already_signed") }}
			</v-alert>
		</v-container>

		<v-container v-if="!docError && !loading">
			<div id="pdf-view" class="col-md-12 col-sm-12 pa-0">
				<auth-user-pdf-viewer :token="token" :authId="authId" :requestId="requestId" :fileName="fileName" @documentError="documentError($event)" />
			</div>
		</v-container>

		<div class="footer-message" v-if="!docError && status !== 'SIGNED' && !loading">
			<div>
				<v-row>
					<v-container class="py-0 fill-height">
						<div class="col-md-8 col-xs-12 text-left terms">
							<v-list>
								<v-list-item>
									<template v-slot:default="{ active }">
										<v-list-item-action>
											<v-checkbox @change="changeConfirm()" dark color="#F36E21" v-model="terms_accepted" :input-value="active"></v-checkbox>
										</v-list-item-action>

										<v-list-item-content class="white--text">
											{{ $t("read_understand_doc") }}
										</v-list-item-content>
									</template>
								</v-list-item>
							</v-list>
						</div>
						<div class="col-md-2 col-6 sign">
							<v-btn large color="#47D764" :disabled="!confirm" block dark class="sign-reject-btn" :loading="loading" @click="signDocument()">
								<v-icon class="mr-2">mdi-pencil-box-outline</v-icon>
								{{ $t("sign") }}
							</v-btn>
						</div>
						<div class="col-md-2 col-6 reject" v-if="!($route.query.noreject === 'true')">
							<v-btn large color="#FF000D" class="sign-reject-btn" block dark @click="reject()">
								<v-icon class="mr-2">mdi-close-circle-outline</v-icon>
								{{ $t("reject") }}
							</v-btn>
						</div>
					</v-container>
				</v-row>
			</div>
		</div>

		<sign-confirm-pad-modal :dialog="padDialog" :errorMsg="errorMsg" :error="error" @submitSignPad="submitSignPad" />
		<certificate-pin-modal :dialog="certificatePinCodeDialog" :errorMsg="errorMsg" :error="error" @submitCertificatePin="submitCertificatePin" />
		<reject-document-modal :dialog="rejectDocumentDialog" :errorMsg="errorMsg" :error="error" :requestId="requestId" :authId="authId" @rejectCertificateDocument="rejectCertificateDocument" id="rejectDocModal" />
		<single-document-summary :dialog="summaryDocumentDialog" :showAlert="showAlert" :alertType="alertType" :alertMsg="alertMsg" :requestId="requestId" :authId="authId" :isApproved="isApproved" />
		<sign-confirm-otp-modal :dialog="otpDialog" @close="otpDialog = false" @submitOtpCode="submitOtpCode" />
	</div>
</template>

<script>
import SignConfirmPadModal from "../components/shared/SignConfirmPadModal.component";
import CertificatePinModal from "../components/shared/CertificatePinModal.component.vue";
import DefaultLoading from "../components/shared/DefaultLoading.component";
import AuthUserPdfViewer from "../components/shared/AuthUserPdfViewer.component";
import RejectDocumentModal from "../components/shared/RejectDocumentModal.component";
import SingleDocumentSummary from "../components/shared/SingleDocumentSummary.component";
import Alerts from "../components/shared/Alerts.component";
import SignConfirmOtpModal from "../components/shared/SignConfirmOtpModal.component";
import { SignaturesService } from "../services/signatures-service";
import moment from "moment";

export default {
	name: "singleDocumentView",
	components: {
		AuthUserPdfViewer,
		CertificatePinModal,
		SignConfirmPadModal,
		RejectDocumentModal,
		DefaultLoading,
		Alerts,
		SingleDocumentSummary,
		SignConfirmOtpModal,
	},

	data() {
		return {
			terms_accepted: null,

			loadingBar: false,
			docError: false,
			error: false,

			showPdf: false,
			otpStatus: false,
			otpDialog: false,
			otpSend: false,
			otpCode: "",
			padDialog: false,
			padStatus: false,
			padImage: "",
			userLatitude: "",
			userLongitude: "",
			errorMsg: "",
			submitLocation: false,

			status: false,

			showAlert: false,
			alertType: "",
			alertMsg: "",
			token: "",
			authId: "",
			requestId: null,
			requestType:"",
			fileName: "",
			loading: true,
			confirm: false,
			rejectDocumentDialog: false,
			signatureTransactionData: null,
			documentDetails: null,
			signaturePadImage: "",
			certificatePinCodeDialog: false,
			summaryDocumentDialog: false,
			isApproved: false
		};
	},

	mounted() {
		this.token = this.$route.query.token ? this.$route.query.token : localStorage.getItem("auth_user_token");
		this.requestId = Number(this.$route.params.id);

		if (this.token && this.requestId) {
			this.loadDocumentData();
		}

		localStorage.setItem("reviewed_list", "");
	},

	computed: {
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		}
	},

	methods: {
		loadDocumentData() {
			if (this.requestId) {
				this.loading = true;

				SignaturesService.getCertificateSignatureDocument(
					this.token,
					this.requestId,
					this.$route.query.authId || null
				)
					.then((response) => {
						this.documentDetails = response.data;
						this.otpStatus = this.documentDetails.otp;
						this.requestType = this.documentDetails.type
						this.$store.commit('forms/setHasSignerForm', this.hasSignerForm);
						this.$store.commit('forms/setHasPendingSignerForm', this.hasPendingSignerForm);
						this.$store.commit('attachments/setHasAttachments', this.hasAttachments);
						this.loadPdf();

						let openSignatureOrSendOtp = localStorage.getItem('open_signature_or_send_otp');
						localStorage.removeItem('open_signature_or_send_otp');

						if (openSignatureOrSendOtp) {
							this.signDocument();
						}
					})
					.catch(() => {
						this.loading = false;
					});
			}
		},
		documentError(value) {
			this.docError = value;
			if (this.docError) {
				this.setAlert(true, "error", this.$t("document_error"));
			}
		},
		loadPdf() {
			const currentDocument = this.documentDetails;
			this.authId = this.$route.query.authId ? this.$route.query.authId : currentDocument["signerId"]["id"];
			this.fileName = currentDocument["signatureTransaction"]["description"];
			this.submitButton = this.currentDocument["status"] == "SIGNED" ? true : false;
			this.loading = false;
		},
		changeConfirm() {
			this.confirm = !this.confirm;
		},
		hideAlerts(time) {
			setTimeout(
				function () {
					this.setAlert(false, "", "");
				}.bind(this),
				time
			);
		},
		reject() {
			console.log("Reject");
			// this.rejectDocumentDialog = true;
			this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}rejected` });
		},
		rejectCertificateDocument() {
			console.log("Reject certificate document, tras rechazar en el modal redircciona a summary");
			// this.setAlert(true, "success", this.$t("signature_request_rejected"));
			// this.summaryDocumentDialog = true;
			this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}summary` });
			// this.rejectDocumentDialog = false;
			// this.isApproved = false;

		},
		setAlert(show, alertType, alertMsg) {
			this.showAlert = show;
			this.alertType = alertType;
			this.alertMsg = alertMsg;
		},
		signDocument() {
			this.error = false;
			console.log("sign document");
			if (this.documentDetails.pad) {
				this.padDialog = true;
				console.log("pad");
			} else if(this.requestType == "HUMAN" && this.documentDetails.pad === false){
				this.certificatePinCodeDialog = true;
				console.log("human and !pad");
			} else if(this.documentDetails.otp){
				console.log("otp");
				this.sendOtpCode();
			}else {
				console.log("else");
				this.submitDocument();
			}
		},
		submitSignPad(signature) {
			this.signaturePadImage = signature;
			this.padDialog = false;
			console.log("submit sign pad");
			if (this.documentDetails.otp && this.requestType != "HUMAN" ) {
				this.sendOtpCode();
			console.log("1");
			} else if (this.requestType == "HUMAN" ){
				this.certificatePinCodeDialog = true;
				console.log("2");
			} else {
				console.log("else");
				this.submitDocument();
			}
		},
		submitCertificatePin(pinCode) {
			this.certificatePinCode = pinCode;
			this.signCertificateSignature();
		},
		async signCertificateSignature() {
			console.log("sign certificate signature");
			this.loading = true;
			let data = { comments: "", password: this.certificatePinCode };
			if (this.documentDetails.pad) {
				data["signatureImage"] = this.signaturePadImage;
			}

			await SignaturesService.signCertificateSignaturesDocument(
				this.token,
				data,
				this.requestId
			)
				.then((response) => {
					if (response.status == 200) {
						this.isApproved = true;
						// this.summaryDocumentDialog = true;
						// this.setAlert(true, "success", this.$t("doc_signed_correctly"));
						this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}summary` });
					} else {
						this.setAlert(true, "error", this.$t("server_error"));
					}
					this.loading = false;
				})
				.catch((error) => {
					if (error.response.status == 422) {
						this.errorMsg = error.response.data.description == "SYSTEM.PKCS11_PIN_INCORRECT"
							? this.$t("document_list.invalid_pin_code")
							: this.$t("server_error");
						if (error.response.data.description == "Missing otp code") {
							this.otpDialog = true;
						}
					} else {
						this.errorMsg = this.$t("server_error");
					}
					this.error = true;
					this.loading = false;
				});
		},
		sendOtpCode() {
			this.error = false;
			if (this.requestId) {
				this.loadingBar = true;
				this.otpDialog = true;

				let data = {
					comments: "",
					authId: this.authId,
					location:
					this.userLatitude && this.userLongitude
						? this.userLatitude + ", " + this.userLongitude
						: "",
					lastRefresh: moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ"),
						otp: "",
						signatureImage: "",
					};
				// console.log ("this.token" + this.token);
				// console.log ("this.authId"+ this.authId);
				SignaturesService[this.token && this.authId ? `sendOtpOneTimeAuth` : `sendOtp`](this.requestId, data, this.token, this.authId).then((response) => {
					if (response.status == 200) {
						this.isApproved = true;
						// this.summaryDocumentDialog = true;
						// this.setAlert(true, "success", this.$t("doc_signed_correctly"));
						this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}summary` });
					}
					else {
						this.setAlert(true, "error", this.$t("server_error"));
					}
					this.loadingBar = false;
				})
					.catch((error) => {
						this.loadingBar = false;
						if (error.response.status == 422) {
							this.otpSend = true;
						}
					});
			} else {
				this.error = true;
			}
		},

		submitOtpCode(otpCode) {
			this.otpCode = otpCode;
			if (this.otpStatus) {
				this.otpDialog = false;
				this.submitDocument();
			}
		},

		submitDocument() {
			this.error = false;
			if (this.requestId) {
				this.loadingBar = true;
				SignaturesService.signCertificateSignaturesDocument(this.token, this.getSubmitData(), this.requestId)
					.then((response) => {
						this.loadingBar = false;
						this.status = response.data.status;
						if (response.data.status === "SIGNED") {
							this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}summary` });
						} else {
							this.showPdf = true;
						}
					})
					.catch((error) => {
						this.loadingBar = false;
						if (error.response.status == 422) {
							this.error = true;
							this.errorMsg = error.response.data.description == "Invalid otp code"
								? this.$t("invalid_otp")
								: error.response.data.description;
						}
						if (error.response.status == 403) {
							this.error = true;
							this.errorMsg = error.response.data.description;
						}
						if (error.response.status == 500) {
							this.error = true;
							this.errorMsg = this.$t("server_error");
						}
						this.showPdf = true;
						document.body.scrollTop = 0;
						document.documentElement.scrollTop = 0;
					});
			} else {
				this.error = true;
			}
		},
		getSubmitData() {
			let submitData = {
				comments: "",
				location: this.userLatitude && this.userLongitude
					? this.userLatitude + ", " + this.userLongitude
					: "",
				lastRefresh: moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ"),
			};

			const requestType = localStorage.getItem("type");
			if (requestType == "ONETIMEWITHID") {
				submitData["authId"] = this.authId;
			}

			if (this.otpStatus) {
				submitData["otp"] = this.otpCode;
			}

			if (this.padStatus) {
				submitData["signatureImage"] = this.padImage;
			} else if (this.documentDetails.pad) {
				submitData["signatureImage"] = this.signaturePadImage;
			}
			
			return submitData;
		},
	}
};
</script>
