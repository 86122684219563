<template>
  <v-overlay v-model="dialog" color="#0E82B9" opacity="0.95">
    <v-container class="fill-height">
      <v-row class="pa-2 text-center">
        <div v-if="error" class="col-sm-10 col-md-6">
          <v-alert class="mt-5 red" border="left" text>
            {{ errorMsg }}
          </v-alert>
        </div>

        <div class="col-12 white--text text-center">
          {{ $t("document_reject.why_do_you_refuse_to_sign_this_document") }}
        </div>
        <v-row class="d-flex justify-center">
          <div class="col-md-12 col-xs-12 col-11 mt-10">
            <v-textarea
              v-model="comment"
              rows="8"
              solo
              light
              counter
            ></v-textarea>
          </div>
        </v-row>
      </v-row>
      <div class="col-md-12 col-xs-12 mt-1 px-5 reject-modal-button-wrapper">
        <v-btn
          class="reject-modal-save-button"
          large
          color="secondary"
          block
          dark
          @click="reject()"
          :disabled="this.comment.length < 1"
          >{{ $t("document_reject.save_and_continue") }}
        </v-btn>
      </div>
      <DefaultLoading v-if="loading" :loading="loading" />
    </v-container>
  </v-overlay>
</template>

<script>
import DefaultLoading from "../../components/shared/DefaultLoading.component";
import { SignaturesService } from "../../services/signatures-service";
export default {
  name: "reject-document-modal",
  props: {
    dialog: Boolean,
    error: Boolean,
    errorMsg: String,
    requestId: Number,
    authId: String,
  },
  components: {
    DefaultLoading,
  },
  data: function() {
    return {
      documentId: "",
      comment: "",
      loading: false,
      rejectSuccess: false,
    };
  },
  methods: {
    getRejectedCount() {
      return localStorage.getItem("rejected_doc_count");
    },
    resetValues() {
      this.rejectSuccess = false;
      this.error = false;
      this.errorMsg = "";
      this.successMsg = "";
      this.comment = "";
    },
    reject() {
      this.error = false;
      if (this.requestId) {
        this.loading = true;
        this.rejectSuccess = false;
        let data = { comments: this.comment, authId: this.authId };
        console.log("shared reject document modal ");
        SignaturesService.rejectCertificateDocument(
          localStorage.getItem("auth_user_token"),
          data,
          this.requestId
        )
          .then((response) => {
            this.loading = false;
            if (response.data.status === "REJECTED") {
              this.rejectSuccess = true;
              this.successMsg = this.$t(
                "document_reject.successfully_rejected"
              );
              this.comment = "";
              this.signatureTransactionData =
                response.data.signatureTransaction;
              this.resetValues();
              this.$emit("rejectCertificateDocument", {
                documentId: this.documentId,
                reason: this.comment,
              });
            }

            localStorage.setItem(
              "rejected_doc_count",
              Number(this.getRejectedCount()) + 1
            );
          })
          .catch((error) => {
            this.loading = false;
            this.rejectSuccess = false;
            this.error = true;
            this.errorMsg = error.response.data.description;
          });
      } else {
        this.error = true;
      }
    },
  },
};
</script>
